import { Theme } from '@duckma/react-ds'

// const random = () =>
//   '#000000'.replace(/0/g, function () {
//     return (~~(Math.random() * 16)).toString(16)
//   })

// const randomTheme: Theme = {
//   black: random(),
//   white: random(),
//   primary100: random(),
//   primary50: random(),
//   primary20: random(),
//   secondary100: random(),
//   secondary50: random(),
//   secondary20: random(),
//   tertiary100: random(),
//   tertiary50: random(),
//   tertiary20: random(),
//   gray100: random(),
//   gray50: random(),
//   gray20: random(),
//   success100: random(),
//   success50: random(),
//   success20: random(),
//   warning100: random(),
//   warning50: random(),
//   warning20: random(),
//   danger100: random(),
//   danger50: random(),
//   danger20: random(),
// }

const realTheme: Theme = {
  black: '#222222',
  white: '#ffffff',
  primary100: '#304A6F',
  primary50: '#203149',
  primary20: '#466CA2',
  secondary100: '#5E5E5E',
  secondary50: '#727272',
  secondary20: '#9B9B9B',
  tertiary100: '#EC823A',
  tertiary50: '#F5A745',
  tertiary20: '#FABD4C',
  gray100: '#828894',
  gray50: '#e5e7eb',
  gray20: '#f7f7f7',
  success100: '#a1dd70',
  success50: '#d0edb8',
  success20: '#e8f6dd',
  warning100: '#fdd043',
  warning50: '#fee69a',
  warning20: '#fff6da',
  danger100: '#ff5959',
  danger50: '#feaeae',
  danger20: '#fedcdc',
}

// const gruvboxTheme: Theme = {
//   black: '#ebdbb2',
//   white: '#3c3836',
//   primary100: '#32302f',
//   primary50: '#665c54',
//   primary20: '#7c6f64',
//   secondary100: '#458588',
//   secondary50: '#83a598',
//   secondary20: '#83a598',
//   tertiary100: '#b16286',
//   tertiary50: '#d3869b',
//   tertiary20: '#d3869b',
//   gray100: '#7c6f64',
//   gray50: '#665c54',
//   gray20: '#504945',
//   success100: '#98971a',
//   success50: '#b8bb26',
//   success20: '#b8bb26',
//   warning100: '#d79921',
//   warning50: '#fabd2f',
//   warning20: '#fabd2f',
//   danger100: '#cc241d',
//   danger50: '#fb4934',
//   danger20: '#fb4934',
// }

export const theme = realTheme
