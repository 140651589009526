import moment from 'moment'

const capitalizeFirst = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const calendarLocalization = {
  date: 'data',
  time: 'ora',
  event: 'evento',
  allDay: 'tutto il giorno',
  week: 'settimana',
  work_week: 'settimana lavorativa',
  day: 'giorno',
  month: 'mese',
  previous: 'precedente',
  next: 'successivo',
  yesterday: 'ieri',
  tomorrow: 'domani',
  today: 'oggi',
  agenda: 'agenda',
  showMore: (count: number) => 'Mostra altri ' + count,
  noEventsInRange: 'Nessun evento disponibile',
}

export const calendarFormats = {
  dateFormat: 'DD',
  dayFormat: 'dddd DD/MM',
  weekdayFormat: 'dddd',
  timeGutterFormat: 'HH:mm',
  monthHeaderFormat: (date: Date) => capitalizeFirst(moment(date).format('MMMM')),
  dayRangeHeaderFormat: (range: { start: Date; end: Date }) =>
    'Settimana dal ' +
    moment(range.start).format('DD/MM') +
    ' al ' +
    moment(range.end).format('DD/MM'),
  dayHeaderFormat: (date: Date) => capitalizeFirst(moment(date).format('dddd DD MMMM')),
  agendaHeaderFormat: (range: { start: Date; end: Date }) =>
    'Da ' +
    moment(range.start).format('dddd DD/MM') +
    ' a ' +
    moment(range.end).format('dddd DD/MM'),
}
