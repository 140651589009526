import React, { useState, useEffect, useContext } from 'react'
import { Input } from '@duckma/react-ds'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

import { Validator } from '../utils/validators'
import { Formatter } from '../utils/formatters'
import { HtmlEditor } from './HtmlEditor'
import { usePrevious } from '../hooks/usePrevious'

type FieldProps = React.ComponentProps<typeof Input> & {
  validator?: Validator
  formatter?: Formatter<string>
  fieldName?: string
}
export const ControlledField = (props: FieldProps) => {
  const {
    validator = (a: any, b: any) => null,
    formatter = _.identity,
    fieldName = props.name,
  } = props

  const { fields, onChange, loading } = useContext(FormContext)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!_.has(fields, fieldName)) {
      onChange(fieldName, '', validator('', _.mapValues(fields, 'value')))
    }
  }, [fields, fieldName, validator, onChange])

  useEffect(() => {
    // When fields change, rerun the validator. If status or error doesn't change, don't fire
    if (fields && fields[fieldName]) {
      const error = validator(fields[fieldName]?.value, _.mapValues(fields, 'value'))
      if (error !== fields[fieldName].error) {
        onChange(fieldName, fields[fieldName].value, error)
      }
    }
  }, [fields, fieldName, validator, onChange])

  if (loading) {
    return <Skeleton />
  }

  return (
    <Input
      {...props}
      onBlur={() => setShowError(true)}
      valid={!showError || fields[fieldName]?.error === null}
      errorText={_.get(fields, [fieldName, 'error'], '') || ''}
      value={formatter(_.get(fields, [fieldName, 'value'], ''))}
      onChange={(value) => {
        if (value !== fields[fieldName]?.value) {
          onChange(fieldName, value, validator(value, _.mapValues(fields, 'value')))
        }
      }}
    />
  )
}

type HtmlEditorProps = React.ComponentProps<typeof HtmlEditor> & {
  fieldName: string
  validator?: Validator
}
export const ControlledHtmlEditor = (props: HtmlEditorProps) => {
  const { validator = (a: any, b: any) => null, fieldName } = props

  const { fields, onChange, loading } = useContext(FormContext)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!_.has(fields, fieldName)) {
      onChange(fieldName, '', validator('', _.mapValues(fields, 'value')))
    }
  }, [fields, fieldName, validator, onChange])

  useEffect(() => {
    // When fields change, rerun the validator. If status or error doesn't change, don't fire
    if (fields && fields[fieldName]) {
      const error = validator(fields[fieldName]?.value, _.mapValues(fields, 'value'))
      if (error !== fields[fieldName].error) {
        onChange(fieldName, fields[fieldName].value, error)
      }
    }
  }, [fields, fieldName, validator, onChange])

  if (loading) {
    return <Skeleton />
  }

  return (
    <HtmlEditor
      {...props}
      onBlur={() => setShowError(true)}
      valid={!showError || fields[fieldName]?.error === null}
      errorText={_.get(fields, [fieldName, 'error'], '') || ''}
      value={_.get(fields, [fieldName, 'value'], '')}
      onChange={(value) => {
        if (value !== fields[fieldName]?.value) {
          onChange(fieldName, value, validator(value, _.mapValues(fields, 'value')))
        }
      }}
    />
  )
}

type FieldState = {
  value: string
  error: string | null
}
type Context = {
  fields: {
    [fieldName: string]: FieldState
  }
  onChange: (fieldName: string, value: string, error: string | null) => void
  loading: boolean
}
const FormContext = React.createContext<Context>({
  onChange: (...rest) => {},
  loading: false,
  fields: {},
})
export const ControlledForm: React.FC<{
  onChange?: (fields: { [fieldName: string]: string }, valid: boolean) => void
  initialValues: null | { [fieldName: string]: string | number | undefined }
}> = ({ children, onChange: externalOnChange, initialValues = {} }) => {
  const [values, setValues] = useState<{ [fieldName: string]: FieldState }>()
  const previousInitialValues = usePrevious(initialValues)

  useEffect(() => {
    if (previousInitialValues === null && initialValues != null) {
      setValues(
        _.mapValues(initialValues, (value) => ({
          value: `${value}` || '',
          error: null,
        }))
      )
    }
  }, [previousInitialValues, initialValues])

  const onChange = (fieldName: string, value: string, error: string | null) => {
    setValues((values) => ({ ...values, [fieldName]: { value, error } }))
  }

  useEffect(() => {
    if (externalOnChange) {
      externalOnChange(
        _.mapValues(values, 'value'),
        _.map(values, 'error').every((err) => err === null)
      )
    }
  }, [externalOnChange, values])

  return (
    <FormContext.Provider
      value={{ onChange, fields: values || {}, loading: initialValues == null }}
    >
      {children}
    </FormContext.Provider>
  )
}
