import React, { useState } from 'react'
import styled from 'styled-components'
import { Column, Lookup } from 'devextreme-react/data-grid'

import { CustomDataGrid } from '../../components/CustomDataGrid'
import { api } from '../../data/api'
import CustomStore from 'devextreme/data/custom_store'
import { storage } from '../../data/storage'

const companiesDataSource = {
  store: new CustomStore({
    key: 'id',
    load: async (loadOptions: unknown) =>
      api.getCompanies().then(({ items, pagination }) => ({
        data: items,
        totalCount: pagination.total_items,
        summary: pagination.total_pages,
        groupCount: pagination.page_size,
      })),
    byKey: (key: string) => api.getCompany({ id: key }),
  }),
}

export const MachinesPage = () => {
  const [author] = useState(storage.getUser())
  return (
    <Container>
      <CustomDataGrid
        exportName="Macchine"
        onLoad={api.getMachines}
        onUpdate={api.updateMachine}
        onRemove={api.deleteMachine}
      >
        {author.role === 'super_admin' && (
          <Column dataField="company.id" caption="Azienda" dataType="string" allowEditing={false}>
            <Lookup
              dataSource={companiesDataSource}
              displayExpr={(company: any) => `${company.name}`}
              valueExpr="id"
            />
          </Column>
        )}
        <Column dataField="code" caption="Nome macchina" dataType="string" />
      </CustomDataGrid>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`
