import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Tabs } from '@duckma/react-ds'

import { CalendarTab } from './components/CalendarTab'
import { RequestsTab } from './components/RequestsTab'
import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { useLocation } from 'react-router-dom'

export const EventsPage = () => {
  const location = useLocation()
  const [tab, setTab] = useState('calendar')
  const [requestCount, setRequestCount] = useState<number | null>(null)
  const [getRequests, { data }] = useRequest(api.getEvents)

  const onChangeCount = useCallback(
    () => getRequests({ page: 0, page_size: 1, statuses: ['requested', 'delete_requested'] }),
    [getRequests]
  )
  useEffect(() => {
    onChangeCount()
  }, [onChangeCount])

  useEffect(() => {
    if (data) {
      setRequestCount(data.pagination.total_pages * data.pagination.page_size)
    }
  }, [data])

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setTab('calendar')
    }
  }, [location])

  return (
    <Container>
      <Tabs
        currentTab={tab}
        onTabChange={setTab}
        tabs={[
          {
            key: 'calendar',
            label: 'Calendario',
            child: <CalendarTab />,
          },
          {
            key: 'requests',
            label: requestCount !== null ? `Richieste (${requestCount})` : 'Richieste',
            child: <RequestsTab onChangeCount={onChangeCount} />,
          },
        ]}
      ></Tabs>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`
