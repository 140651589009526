import React, { useState } from 'react'
import styled from 'styled-components'
import { SelectBox } from 'devextreme-react'
import { Column, Lookup } from 'devextreme-react/data-grid'
import 'jspdf-autotable'

import { api } from '../../data/api'
import { CustomDataGrid } from '../../components/CustomDataGrid'
import CustomStore from 'devextreme/data/custom_store'
import { storage } from '../../data/storage'

export const tags = [
  {
    id: 'user',
    name: 'Utente',
  },
  {
    id: 'admin',
    name: 'Admin',
  },
  {
    id: 'super_admin',
    name: 'Super Admin',
  },
]

const companiesDataSource = {
  store: new CustomStore({
    key: 'id',
    load: async (loadOptions: unknown) =>
      api.getCompanies().then(({ items, pagination }) => ({
        data: items,
        totalCount: pagination.total_items,
        summary: pagination.total_pages,
        groupCount: pagination.page_size,
      })),
    byKey: (key: string) => api.getCompany({ id: key }),
  }),
}

const downloadFile = (data: any, fileName: string, fileType: string) => {
  const blob = new Blob([data], { type: fileType })
  const a = document.createElement('a')
  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })
  a.dispatchEvent(clickEvt)
  a.remove()
}

export const UsersPage = () => {
  const [author] = useState(storage.getUser())
  return (
    <Container>
      <CustomDataGrid
        exportName="Utenti"
        onLoad={api.getUsers}
        onRemove={api.deleteUser}
        onUpdate={api.updateUser}
        customToolbarButtons={[
          {
            text: 'Export CSV',
            onClick: async () => {
              window.open(
                (
                  await api.exportUsers({
                    email: 'Email',
                    first_name: 'Nome',
                    last_name: 'Cognome',
                    tax_code: 'Codice Fiscale',
                    phone_number: 'Numero di Telefono',
                    blocked: 'Bloccato',
                    disable_notifications: 'Notifiche Disabilitate',
                  })
                ).url,
                '_blank',
                'noopener,noreferrer'
              )
            },
          },
        ]}
      >
        {author.role === 'super_admin' && (
          <Column dataField="company.id" caption="Azienda" dataType="string" allowEditing={false}>
            <Lookup
              dataSource={companiesDataSource}
              displayExpr={(company: any) => `${company.name}`}
              valueExpr="id"
            />
          </Column>
        )}
        <Column dataField="email" dataType="string" allowEditing={false} />
        <Column dataField="first_name" caption="Nome" dataType="string" />
        <Column dataField="last_name" caption="Cognome" dataType="string" />
        <Column dataField="tax_code" caption="Codice Fiscale" dataType="string" />
        <Column dataField="phone_number" caption="Numero di telefono" dataType="string" />
        <Column
          dataField="role"
          caption="Ruolo"
          dataType="string"
          editCellRender={(cell) => (
            <SelectBox
              defaultValue={cell.value}
              {...cell.column.lookup}
              itemRender={(data) => (data ? data.name : '(Tutti)')}
              onValueChanged={({ value }) => cell.setValue(value)}
            />
          )}
        >
          <Lookup dataSource={tags} displayExpr="name" valueExpr="id" />
        </Column>
        <Column dataField="blocked" caption="Bloccato" dataType="boolean" />
        <Column
          dataField="disable_notifications"
          caption="Notifiche disabilitate"
          dataType="boolean"
        />
      </CustomDataGrid>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`
