import React, { useEffect, useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { SectionTitle, HeaderTitle, Icon } from '@duckma/react-ds'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import moment from 'moment'
import { Header } from '../../components/Header'
import { DashboardDeficit, PaginatedResponse } from '../../data/models'
import DataGrid, {
  Column,
  ColumnFixing,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid'
import Skeleton from 'react-loading-skeleton'
import { durationFormatter } from '../../utils/formatters'
import { saveToExcel, saveToPDF } from '../../components/CustomDataGrid'

export const DashboardPage = () => {
  const [getDashboard, { status, data }] = useRequest(api.getDashboard)
  const [date, setDate] = useState(moment())

  const getUsersWithParams = useCallback(
    () =>
      getDashboard({
        date_from: date.startOf('day').toISOString(),
        date_to: date.endOf('day').toISOString(),
        deficits_order_by: 'logged_minutes',
        deficits_order_direction: 'asc',
        deficits_page: 0,
        deficits_page_size: 100000,
        regulars_order_by: 'logged_minutes',
        regulars_order_direction: 'asc',
        regulars_page: 0,
        regulars_page_size: 10000,
      }),
    [getDashboard, date]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  return (
    <Container>
      <Header
        title="Dashboard"
        rightWidgets={[<DateSelectorWidget value={date} onChange={setDate} />]}
        style={{ padding: 0 }}
      />
      <WidgetCard width="100%">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <SectionTitle text="Ore inserite nella giornata" color="black" />
            <CardDescription>Log ore totali giornaliere</CardDescription>
          </div>
          {status !== 'success' ? (
            <Skeleton height="45px" width="190px" />
          ) : (
            <HeaderTitle color="gray100" style={{ fontSize: '32px', fontWeight: '600' }}>
              {data ? Math.floor(data.total_logged_minutes / 60) : 0}/
              {data ? Math.floor(data.total_target_minutes / 60) : 0} ore
            </HeaderTitle>
          )}
        </div>
      </WidgetCard>
      <TotalCard
        title="Ore inserite parzialmente"
        logs={data?.deficits}
        dailyTarget={data?.daily_target}
        key={'deficits' + date.toISOString()}
      />
      <TotalCard
        title="Ore lavorate della giornata"
        logs={data?.regulars}
        dailyTarget={data?.daily_target}
        key={'regulars' + date.toISOString()}
      />
    </Container>
  )
}

export const TotalCard: React.FC<{
  logs: PaginatedResponse<DashboardDeficit> | undefined
  title: string
  dailyTarget: number | undefined
}> = ({ logs, title, dailyTarget }) => {
  const gridRef = useRef(null)

  return (
    <WidgetCard width="100%" style={{ marginTop: '40px' }}>
      <SectionTitle text={title} color="black" />
      <DataGrid
        ref={gridRef}
        dataSource={{ store: logs?.items ?? [] }}
        showBorders
        allowColumnReordering
        columnAutoWidth
        onExporting={(e) => saveToExcel(e, title)}
        onToolbarPreparing={(e) => {
          e.toolbarOptions?.items?.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'exportpdf',
              onClick: () => saveToPDF(gridRef.current, title),
            },
          })
        }}
      >
        <FilterRow visible />
        <GroupPanel visible />
        <SearchPanel visible />
        <ColumnFixing enabled />
        <Scrolling columnRenderingMode="virtual" />
        <Grouping autoExpandAll />
        <Column dataField="first_name" caption="Nome" dataType="string" />
        <Column dataField="last_name" caption="Cognome" dataType="string" />
        <Column
          dataField="logged_minutes"
          caption="Ore loggate"
          dataType="string"
          format={durationFormatter}
        />
        <Export enabled />
        <Paging defaultPageSize={10} />
        <Pager showPageSizeSelector allowedPageSizes={[5, 10, 20]} />
      </DataGrid>
    </WidgetCard>
  )
}

const DateSelectorWidget: React.FC<{
  value: moment.Moment
  onChange: (date: moment.Moment) => void
}> = ({ value, onChange }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <ClickableIcon
      name="angle-left"
      color="black"
      size="24px"
      onClick={() => onChange(moment(value).subtract(1, 'day'))}
    />
    <SectionTitle text={value.format('DD/MM/YYYY')} color="black" style={{ fontWeight: 600 }} />
    <ClickableIcon
      name="angle-right"
      color="black"
      size="24px"
      onClick={() => onChange(moment(value).add(1, 'day'))}
    />
    <ClickableIcon
      name="calendar-today"
      color="primary100"
      size="24px"
      onClick={() => onChange(moment())}
      style={{ marginLeft: '30px' }}
    />
  </div>
)

const ClickableIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 160px auto auto;
  align-items: start;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 50px;
`

const WidgetCard = styled.div<{ width: string }>`
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 34px 0 rgba(130, 136, 148, 0.21);
  background-color: white;
  font-family: 'Open Sans', sans-serif;
  padding: 34px 52px;
  width: ${(props) => props.width};
`

const CardDescription = styled.div`
  font-size: 14px;
  margin-top: 2vh;
  font-weight: 300;
`
