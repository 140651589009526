import React, { useState } from 'react'
import styled from 'styled-components'

import { api, ListParams } from '../../../data/api'
import { CustomDataGrid } from '../../../components/CustomDataGrid'
import { Column, Lookup } from 'devextreme-react/data-grid'
import { SelectBox } from 'devextreme-react'
import { dateFormatter, durationFormatter, ellipse } from '../../../utils/formatters'
import { EditDuration } from '../../../utils/durationEditor'
import CustomStore from 'devextreme/data/custom_store'
import { storage } from '../../../data/storage'
import { OrderStatus } from '../../../data/models'

export const statuses = [
  {
    id: 'active',
    name: 'Commessa aperta',
  },
  {
    id: 'pending',
    name: 'Commessa sospesa',
  },
  {
    id: 'closed',
    name: 'Commessa chiusa',
  },
]

const companiesDataSource = {
  store: new CustomStore({
    key: 'id',
    load: async (loadOptions: unknown) =>
      api.getCompanies().then(({ items, pagination }) => ({
        data: items,
        totalCount: pagination.total_items,
        summary: pagination.total_pages,
        groupCount: pagination.page_size,
      })),
    byKey: (key: string) => api.getCompany({ id: key }),
  }),
}

export const OrdersTabPage: React.FC<{ status: OrderStatus }> = ({ status }) => {
  const [author] = useState(storage.getUser())
  return (
    <CustomDataGrid
      exportName="Commesse"
      remoteOperations={{ filtering: true, paging: true, sorting: true, summary: true }}
      onLoad={(params?: Record<string, any>) =>
        api.getPaginatedOrders({
          ...params,
          status,
        })
      }
      onUpdate={api.updateOrder}
      onEditorPreparing={(e) => {
        if (e.dataField === 'description' && e.parentType === 'dataRow') {
          const defaultValueChangeHandler = e.editorOptions.onValueChanged
          e.editorName = 'dxTextArea'
          e.editorOptions.height = 200
          e.editorOptions.onValueChanged = function (args: any) {
            defaultValueChangeHandler(args)
          }
        }
      }}
      customToolbarButtons={[
        {
          text: 'Export CSV',
          onClick: async () => {
            window.open(
              (
                await api.exportOrders({
                  customer: 'Cliente',
                  code: 'Codice',
                  description: 'Descrizione',
                  budget_minutes: 'Minuti a Budget',
                  total_logged_minutes: 'Totale Minuti Loggati',
                  status: 'Stato',
                  type: 'Tipo',
                  ddt: 'DDT',
                  delivery_date: 'Data Consegna',
                  created_at: 'Data Creazione',
                })
              ).url,
              '_blank',
              'noopener,noreferrer'
            )
          },
        },
      ]}
    >
      {author.role === 'super_admin' && (
        <Column dataField="company.id" caption="Azienda" dataType="string" allowEditing={false}>
          <Lookup
            dataSource={companiesDataSource}
            displayExpr={(company: any) => `${company.name}`}
            valueExpr="id"
          />
        </Column>
      )}
      <Column dataField="customer" caption="Cliente" dataType="string" />
      <Column dataField="code" caption="Codice" dataType="string" />
      <Column
        dataField="description"
        caption="Descrizione"
        dataType="string"
        format={ellipse}
        width="20vw"
      />

      <Column dataField="delivery_date" caption="Data di consegna" dataType="date" />
      <Column dataField="ddt" caption="DDT" dataType="string" />
      <Column
        dataField="budget_minutes"
        caption="Budget ore"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="machine_logged_minutes"
        caption="Ore totali Macchina"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        allowEditing={false}
        allowSorting={false}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="human_logged_minutes"
        caption="Ore totali Uomo"
        dataType="string"
        editorOptions={{ width: 200 }}
        format={durationFormatter}
        allowEditing={false}
        allowSorting={false}
        editCellComponent={EditDuration}
      />
      <Column
        dataField="total_logged_minutes"
        caption="Ore totali loggate"
        dataType="string"
        allowEditing={false}
        format={durationFormatter}
      />
      <Column
        dataField="status"
        caption="Stato"
        dataType="string"
        editCellRender={(cell) => (
          <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            itemRender={(data) => (data ? data.name : '(Tutti)')}
            onValueChanged={({ value }) => cell.setValue(value)}
          />
        )}
      >
        <Lookup dataSource={statuses} displayExpr="name" valueExpr="id" />
      </Column>
    </CustomDataGrid>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  * > textarea {
    box-sizing: border-box; /* fit parent width */
    height: 200px !important;
    line-height: 1.2 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`
