import React, { useState } from 'react'
import styled from 'styled-components'
import { Label } from '@duckma/react-ds'
import { GithubPicker } from 'react-color'

type Props = {
  value: string
  onChange: (color: string) => void
}
export const ColorPicker = ({ value, onChange }: Props) => {
  const [isPickerOpen, setPickerOpen] = useState(false)

  return (
    <ColorContainer>
      <TextContainer>
        <Label text="Colore" color="black" />
        <ColorSquare color={value} tabIndex={0} onFocus={() => setPickerOpen(true)} />
      </TextContainer>
      <PickerContainer showPicker={isPickerOpen}>
        {isPickerOpen && <CloseCover onClick={() => setPickerOpen(false)} />}
        <GithubPicker
          triangle="top-right"
          color={value}
          onChange={({ hex }) => {
            setPickerOpen(false)
            onChange(hex)
          }}
          colors={[
            '#DD7631',
            '#16817A',
            '#FCBF1E',
            '#142850',
            '#862A5C',
            '#0779E4',
            '#FFBCBC',
            '#4D4C7D',
            '#EB4559',
            '#2B580C',
          ]}
        />
      </PickerContainer>
    </ColorContainer>
  )
}

const ColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 36px;
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ColorSquare = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  outline: none;
  border-radius: 4px;
`

const PickerContainer = styled.div<{ showPicker: boolean }>`
  position: relative;
  z-index: ${(props) => (props.showPicker ? 2 : -1)};
  top: 20px;
  right: -3px;
  align-self: flex-end;
  opacity: ${(props) => (props.showPicker ? '1' : '0')};
  transition: opacity 0.3s ease;
  .github-picker {
    align-items: center;
    justify-content: space-around;
    > span {
      margin: 5px;
      > div {
        outline: none !important;
        box-shadow: none !important;
        > span > div {
          border-radius: 4px;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
  }
`

const CloseCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
