import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Modal, Button, DateTimePicker } from '@duckma/react-ds'
import it from 'date-fns/locale/it'
import { saveAs } from 'file-saver'

import { api } from '../../../data/api'
import { useRequest } from '../../../hooks/useRequest'
import moment from 'moment'

const formatDate = (date: Date) => moment(date).format('YYYY-MM-DD')
export const ExportModal: React.FC<{
  open: boolean
  onExport: (from: Date, to: Date) => void
  onClose?: () => void
}> = ({ open, onExport, onClose = () => {} }) => {
  // const [request, { status, data }, reset] = useRequest(api.getLogsCSV)
  const [from, setFrom] = useState(new Date())
  const [to, setTo] = useState(new Date())

  return (
    <StyledModal open={open} onClose={onClose} title="Export CSV">
      <Content>
        <div style={{ display: 'flex', width: 'calc(100% - 70px)' }}>
          <DateTimePicker name="data-inizio" locale={it} selected={from} onChange={setFrom} />
          <DateTimePicker
            name="data-fine"
            locale={it}
            selected={to}
            onChange={setTo}
            style={{ marginLeft: '3vw' }}
          />
        </div>
        <div style={{ width: '100%', display: 'flex', flexGrow: 0 }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Esporta CSV"
            radius={4}
            onClick={() => {
              onExport(from, to)
            }}
            style={{ flexGrow: 0, flexBasis: '20%', margin: '35px' }}
          />
        </div>
      </Content>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 35vh;
  left: 30vw;
  right: 30vw;
  bottom: 35vh;
  outline: none;
  border-radius: 10px;
  background-color: white;
  > * {
    background-color: white;
    z-index: 2;
    border-radius: 10px;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  height: calc(30vh - 64px);
  > * {
    width: calc(100% - 100px - 50px);
    border-radius: 10px;
  }
  > :first-child {
    flex-grow: 1;
  }
`
