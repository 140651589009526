/**
 * This file defines various input formatters of general use.
 * Single use functions shouldn't be put here.
 */
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/London')

export type Formatter<T> = (value: T) => string

export const dateFormatter: Formatter<string> = (date: string) => moment(date).format('DD/MM/YYYY')
export const dateTimeFormatter: Formatter<string> = (date: string) =>
  moment(date).format('DD/MM/YYYY HH:mm')

export const durationFormatter: Formatter<number> = (minutes: number) =>
  minutes < 60 ? `${minutes}m` : `${Math.floor(minutes / 60)}h ${Math.floor(minutes) % 60}m`

export const ellipse: Formatter<string> = (value: string) =>
  value.length > 20 ? value.substr(0, 20 - 1) + '&hellip;' : value
