import React, { useState, useEffect, useReducer, useCallback } from 'react'
import styled from 'styled-components'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/it'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Event, Period } from '../../../data/models'
import { calendarLocalization, calendarFormats } from '../../../i18n/localizations'
import { EditEventModal } from './EditEventModal'
import { useRequest } from '../../../hooks/useRequest'
import { api } from '../../../data/api'
import { AddEventModal } from './AddEventModal'
import { useLocation, useHistory } from 'react-router-dom'

moment.locale('it')

type CalendarEvent = { allDay?: boolean; title: string; start: Date; end: Date; resource: Event }

function eventCacheReducer(state: Event[], action: { type: string; events: Event[] }) {
  switch (action.type) {
    case 'add':
      // Merge old events and new ones, but update instead of duplicating
      return [
        ...action.events,
        ...state.filter(
          (oldEvent) => !action.events.some((newEvent) => newEvent.id === oldEvent.id)
        ),
      ]
    case 'delete':
      return [
        ...state.filter(
          (oldEvent) => !action.events.some((newEvent) => newEvent.id === oldEvent.id)
        ),
      ]
  }
  return []
}

export const CalendarTab: React.FC = () => {
  const location = useLocation<{ from?: string; to?: string }>()
  const history = useHistory()
  const [editEvent, setEditEvent] = useState<Event | null>(null)
  const [createPeriod, setCreatePeriod] = useState<null | Period>(null)
  const [request, { status, data }] = useRequest(api.getEvents)
  const [createRequest, { status: createStatus }, resetCreate] = useRequest(api.createEvent)
  const [updateRequest, { status: updateStatus }, resetUpdate] = useRequest(api.updateEvent)
  const [deleteRequest, { status: deleteStatus, data: deleteData }, resetDelete] = useRequest(
    api.deleteEvent
  )

  const [events, editCache] = useReducer(eventCacheReducer, [])
  const [currentRange, setCurrentRange] = useState<{ start: string; end: string }>({
    start: moment().startOf('month').toISOString(),
    end: moment().endOf('month').toISOString(),
  })

  useEffect(() => {
    if (location.pathname.includes('create')) {
      const from = location?.state?.from || moment().toISOString()
      const to = location?.state?.to || moment().add(1, 'hour').toISOString()
      setCreatePeriod({ from, to })
    }
  }, [location])

  const requestWithParams = useCallback(
    () =>
      request({
        from: currentRange.start,
        to: currentRange.end,
        statuses: ['accepted', 'delete_requested'],
      }),
    [request, currentRange]
  )

  useEffect(() => {
    requestWithParams()
  }, [requestWithParams])

  useEffect(() => {
    if (status === 'success' && data) {
      editCache({ type: 'add', events: data.items })
    }
  }, [status, data])

  useEffect(() => {
    if (createStatus === 'success') {
      history.push('/events')
      requestWithParams()
      setCreatePeriod(null)
      resetCreate()
    }
  }, [createStatus, requestWithParams, history, resetCreate])

  useEffect(() => {
    if (updateStatus === 'success') {
      requestWithParams()
      resetUpdate()
      setEditEvent(null)
    }
  }, [updateStatus, requestWithParams, resetUpdate])

  useEffect(() => {
    if (deleteStatus === 'success' && deleteData) {
      requestWithParams()
      resetDelete()
      setEditEvent(null)
      editCache({ type: 'remove', events: [deleteData] })
    }
  }, [deleteStatus, requestWithParams, resetDelete, deleteData])

  return (
    <>
      <AddEventModal
        open={createPeriod !== null}
        period={createPeriod}
        onClose={() => {
          history.push('/events')
          setCreatePeriod(null)
        }}
        onCreate={createRequest}
      />
      <EditEventModal
        open={editEvent !== null}
        event={editEvent}
        onClose={() => setEditEvent(null)}
        onEdit={updateRequest}
        onDelete={deleteRequest}
      />
      <Container>
        <StyledCalendar<CalendarEvent, Event>
          events={events.map((ev) => ({
            title: ev.owner
              ? `${ev.owner.last_name} ${ev.owner.first_name} - ${ev.type.name}`
              : `${ev.title} - ${ev.type.name}`,
            start: new Date(ev.period.from),
            end: new Date(ev.period.to),
            resource: ev,
          }))}
          localizer={momentLocalizer(moment)}
          formats={calendarFormats}
          messages={calendarLocalization}
          culture="it"
          onSelectEvent={(ev) => setEditEvent(ev.resource)}
          onRangeChange={(ev) => {
            let start = new Date().toISOString()
            let end = new Date().toISOString()
            if (ev instanceof Array) {
              if (ev.length === 0) {
                return
              }

              start = moment(ev[0]).startOf('day').toISOString()
              end = moment(ev[0]).endOf('day').toISOString()
              ev.slice(1).forEach((item) => {
                const ts = moment(item)
                if (ts.isBefore(start)) {
                  start = moment(ts).startOf('day').toISOString()
                } else if (ts.isAfter(end)) {
                  end = moment(ts).endOf('day').toISOString()
                }
              })
            } else {
              start = moment(ev.start).startOf('day').toISOString()
              end = moment(ev.end).endOf('day').toISOString()
            }

            setCurrentRange({ start, end })
          }}
          onSelectSlot={(ev) => {
            history.push('/events/create', { from: ev.start, to: ev.end })
          }}
          scrollToTime={moment().hours(8).minutes(0).toDate()}
          components={{
            month: { event: MonthEvent },
            week: { event: WeekEvent },
            day: { event: DayEvent },
            agenda: { event: AgendaEvent },
          }}
          selectable
        />
      </Container>
    </>
  )
}

const getColor = (event?: CalendarEvent) => event?.resource.type.color || '#ABCDAB'
const MonthEvent: React.FC<{ event?: CalendarEvent }> = ({ event }) => (
  <EventWrapper backgroundColor={getColor(event)} radiusTop>
    <em>{event?.title}</em>
  </EventWrapper>
)
const WeekEvent = MonthEvent
const DayEvent: React.FC<{ event?: CalendarEvent }> = ({ event }) => (
  <EventWrapper backgroundColor={getColor(event)}>
    <em>
      {event?.title} - {event?.resource.owner?.email || 'pubblico'}
    </em>
  </EventWrapper>
)
const AgendaEvent: React.FC<{ event?: CalendarEvent }> = ({ event }) => (
  <EventWrapper backgroundColor="#FFF">
    <em>
      {event?.title} - {event?.resource.owner?.email || 'pubblico'}
    </em>
  </EventWrapper>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const StyledCalendar = (styled(Calendar)`
  height: calc(100vh - 286px);
  overflow: scroll;
  min-width: 70vw;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  .rbc-event {
    padding: 0;
    border: 1px solid white;
  }
  .rbc-event-label {
    padding-left: 5px;
    background-color: #ddd;
    color: #222;
    width: 100%;
  }
` as unknown) as typeof Calendar

const EventWrapper = styled.div<{ backgroundColor: string; radiusTop?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border-top-left-radius: ${(props) => (props.radiusTop ? 4 : 0)}px;
  border-top-right-radius: ${(props) => (props.radiusTop ? 4 : 0)}px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 5px;
  em {
    max-height: 100%;
  }
`
