import { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../components/MainLayout'
import _ from 'lodash'

export const useDirty = (
  currentValues: { [key: string]: any } | null,
  initialValues: { [key: string]: any } | null,
  pick?: string[]
) => {
  const [isNew, setNew] = useState(true)
  const { flagDirty } = useContext(SessionContext)

  useEffect(() => {
    if (!currentValues || !initialValues) {
      return
    }
    if (isNew) {
      setNew(false)
      return
    }
    const equal = pick
      ? _.isEqual(_.pick(currentValues, pick), _.pick(initialValues, pick))
      : _.isEqual(currentValues, initialValues)
    if (!equal && _.some(currentValues, (v) => v !== '')) {
      flagDirty()
    }
    // Skipping isNew on purpose: must check from second render onwards
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValues, initialValues, flagDirty])
}
