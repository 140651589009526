import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '@duckma/react-ds'
import _ from 'lodash'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, UpdateMachine } from '../../data/api'
import { useParams } from 'react-router-dom'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useDirty } from '../../hooks/useDirty'
import { Select, SelectEntry } from '../../components/Select'

export const MachineDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [getMachine, { status, data }] = useRequest(api.getMachine)
  const [company, setCompany] = useState<SelectEntry | null>(null)
  const [updateMachine, { status: updateStatus }] = useRequest(api.updateMachine)

  const [values, setValues] = useState<Partial<UpdateMachine>>({})
  const initialValues = useMemo(
    () =>
      data
        ? ({
            ..._.omit(data, ['blocked', 'disable_notifications', 'company']),
            company_id: data.company.id,
          } as UpdateMachine)
        : null,
    [data]
  )

  const onChange = useCallback((v) => {
    setValues(v)
  }, [])

  useEffect(() => {
    if (status === 'idle') {
      getMachine({ id })
    }
  }, [status, getMachine, id])

  useEffect(() => {
    if (data) {
      const { company } = data
      setCompany({ value: company.id, label: `${company.name}` })
    }
  }, [data])

  useDirty(values, initialValues, ['code', 'company_id'])
  useSuccessToast(updateStatus, 'Dati modificati con successo')

  return (
    <Container>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          updateMachine({
            id,
            body: {
              ...(_.pick(values, ['code']) as NonNullable<UpdateMachine>),
            },
          })
        }}
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <Select
              name="company_id"
              label="Azienda"
              emptyMessage="Nessuna azienda trovata"
              placeholder="Seleziona azienda"
              value={company}
              onChange={setCompany}
              getItemsPromise={(search) =>
                api.getCompaniessBySearch(search).then((result) =>
                  result.items.map(({ id, name }) => ({
                    value: id,
                    label: `${name}`,
                  }))
                )
              }
            />
            <ControlledField name="Nome Macchina" fieldName="code" />
          </ControlledForm>
        </FieldsContainer>
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`
