import React from 'react'
import styled from 'styled-components'
import { Icon, HeaderTitle, useTheme } from '@duckma/react-ds'
import { useHistory } from 'react-router-dom'
import Media from 'react-media'

import { useSession } from '../hooks/useSession'

type Props = {
  title: string
  backLink?: { to: string; text: string }
  rightWidgets?: React.ReactNode[]
  dirty?: boolean
  style?: {}
}

export const Header: React.FC<Props> = ({ title, backLink, rightWidgets, dirty, style = {} }) => {
  const theme = useTheme()
  const history = useHistory()
  const { toggleSidebarOpen } = useSession()

  return (
    <Container style={style}>
      {backLink && (
        <BackContainer
          onClick={() => {
            if (dirty) {
              return (window.location.href = backLink.to)
            }
            history.push(backLink.to)
          }}
        >
          <Icon name="arrow-left" color="gray50" size="18px" />
          <BackText color={theme['gray100']}>{backLink.text}</BackText>
        </BackContainer>
      )}
      <TitleContainer>
        <Media queries={{ small: 'screen and (max-width: 600px)' }}>
          {(matches) =>
            matches.small && (
              <HamburgerIcon name="menu" size="36px" color="gray100" onClick={toggleSidebarOpen} />
            )
          }
        </Media>
        <StyledTitle color={'gray100'}>{title}</StyledTitle>
        <div style={{ flexGrow: 1 }} />
        {rightWidgets && (
          <ButtonsContainer>
            {rightWidgets.map((b, i) => (
              <React.Fragment key={i}>{b}</React.Fragment>
            ))}
          </ButtonsContainer>
        )}
      </TitleContainer>
    </Container>
  )
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  padding-left: 50px;
  padding-right: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-between;
  align-items: center;
`

const StyledTitle = styled(HeaderTitle)`
  display: inline-block;
  margin: 0;
`

const BackContainer = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const BackText = styled.p<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 800;
  margin-left: 12px;
  color: ${(props) => props.color};
`

const ButtonsContainer = styled.div`
  display: flex;
  > button {
    margin-left: 20px;
    padding: 18px 12px;
    white-space: nowrap;
  }
`

const HamburgerIcon = styled(Icon)`
  margin-right: 10px;
  cursor: pointer;
`
