import React, { useMemo, useRef } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import { locale, loadMessages } from 'devextreme/localization'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import {
  DataGrid,
  GroupPanel,
  Editing,
  Grouping,
  Paging,
  Pager,
  SearchPanel,
  FilterRow,
  ColumnFixing,
  Scrolling,
  Export,
  IDataGridOptions,
} from 'devextreme-react/data-grid'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

import itTranslation from '../i18n/devextreme-it.json'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { PaginatedResponse } from '../data/models'
import styled from 'styled-components'
import _ from 'lodash'
import { errors } from '../i18n/errors'
import { LoadOptions } from 'devextreme/data/load_options'
import { ListParams } from '../data/api'

loadMessages(itTranslation)
locale('it')

export const saveToExcel = (e: any, title: string) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Foglio 1')

  exportDataGrid({
    component: e.component,
    worksheet: worksheet,
    autoFilterEnabled: true,
    customizeCell: ({ gridCell, excelCell }) => {
      if (gridCell?.rowType === 'data') {
        if (gridCell?.column?.dataType === 'boolean') {
          excelCell.value = gridCell.value ? 'SI' : 'NO'
        }
        if (gridCell?.column?.name?.includes('minutes')) {
          excelCell.value = parseFloat((gridCell.value / 60).toFixed(2).replace(',', '.'))
        }
      }
      if (gridCell?.rowType === 'totalFooter') {
        if (gridCell?.column?.name?.includes('minutes')) {
          const time = gridCell.value as string
          const {
            groups: { minutes = 0, hours = 0 },
          } = /((?<hours>\d+)h )?(?<minutes>\d+)m/.exec(time) as any
          excelCell.value = parseFloat(
            (parseInt(hours) + parseInt(minutes) / 60).toFixed(2).replace(',', '.')
          )
          //([^x]+)
        }
      }
    },
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${title}.xlsx`)
    })
  })
  e.cancel = true
}

export const saveToPDF = (dataGrid: DataGrid | null, title: string) => {
  if (!dataGrid) {
    return
  }
  const doc = new jsPDF()

  exportDataGridToPdf({
    jsPDFDocument: doc,
    component: dataGrid.instance,
  }).then(() => {
    doc.save(`${title}.pdf`)
  })
}

const parseFilter: (filter: string[]) => { [key: string]: string } = (filter: string[]) => {
  return {
    [filter[0]]: filter[2],
  }
}

const parseFilters: (filters: any[]) => { [key: string]: string } = (filters: any[]) => {
  if (typeof filters[0] == 'string') {
    return parseFilter(filters)
  }
  const _filters = filters.reduce((filter, curr, index, filters) => {
    return {
      ...filter,
      ...parseFilters(curr),
    }
  }, {})
  return _filters
}

const loadOptionsToListParams: (loadOptions: LoadOptions) => ListParams = (
  loadOptions: LoadOptions
) => {
  const { take, skip, sort, filter } = loadOptions
  const page = skip && take ? skip / take : 0
  console.log({ loadOptions })
  return {
    ...(filter
      ? {
          ...parseFilters(filter),
        }
      : {}),
    ...(sort && sort[0]
      ? {
          order_by: sort[0].selector,
          order_direction: sort[0].desc ? 'desc' : 'asc',
        }
      : {}),
    page_size: take,
    page,
  }
}

export const CustomDataGrid: React.FC<
  {
    exportName?: string
    customToolbarButtons?: { icon?: string; text?: string; onClick: () => void }[]
    onLoad: (params?: Record<string, any>) => Promise<PaginatedResponse<any>>
    onRemove?: (options: { id: string }) => Promise<any>
    onUpdate: (options: { id: string; body: any }) => Promise<any>
  } & IDataGridOptions
> = ({
  exportName = 'Esportazione',
  customToolbarButtons = [],
  onLoad,
  onRemove,
  onUpdate,
  children,
  ...rest
}) => {
  const gridRef = useRef(null)

  const dataSource = useMemo(
    () => ({
      store: new CustomStore({
        key: 'id',
        load: async (loadOptions: LoadOptions) =>
          onLoad(loadOptionsToListParams(loadOptions)).then(({ items, pagination }) => ({
            data: items,
            totalCount: pagination.total_items,
            summary: pagination.total_pages,
            groupCount: pagination.page_size,
          })),
        remove: onRemove ? (id: string) => onRemove({ id }) : undefined,
        update: (id: string, body: any) =>
          onUpdate({ id, body }).catch(
            (error) => _.get(errors, error.message, errors['server_error'])['it']
          ),
      }),
    }),
    [onLoad, onRemove, onUpdate]
  )

  return (
    <StyledDataGrid
      ref={gridRef}
      dataSource={dataSource}
      showBorders
      allowColumnReordering
      columnAutoWidth
      onToolbarPreparing={(e) => {
        customToolbarButtons?.forEach((tb) => {
          e.toolbarOptions?.items?.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: tb.icon,
              text: tb.text,
              onClick: tb.onClick,
            },
          })
        })
      }}
      {...rest}
    >
      <FilterRow visible />
      <GroupPanel visible />
      <ColumnFixing enabled />
      <Scrolling columnRenderingMode="virtual" />
      <Grouping autoExpandAll />
      <Editing mode="cell" allowUpdating allowDeleting={onRemove !== undefined} />
      {children}
      <Paging defaultPageSize={10} />
      <Pager showPageSizeSelector allowedPageSizes={[5, 10, 20]} />
    </StyledDataGrid>
  )
}

const StyledDataGrid = styled(DataGrid)`
  @media screen and (min-width: 600px) {
    max-width: 75vw;
  }
`
