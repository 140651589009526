import _ from 'lodash'

import {
  PaginatedResponse,
  User,
  Machine,
  Order,
  Event,
  EventType,
  EventStatus,
  Dashboard,
  AggregatedLog,
  AggregatedMachineLog,
  Log,
  MachineLog,
  OrderStatus,
  Role,
  Company,
} from './models'
import { storage } from './storage'

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri +=
        '?' +
        _.map(params, (value, key) =>
          Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
        ).join('&')
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(useToken),
    }).then((res) => this.handleResponse<T>(res))
  }

  private getText = async (
    path: string,
    params?: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri +=
        '?' +
        _.map(params, (value, key) =>
          Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
        ).join('&')
    }
    return fetch(uri, {
      method: 'GET',
      headers: this.getHeaders(useToken),
    }).then((res) => res.text() as Promise<string>)
  }

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`)
    }
    if (response.status === 401) {
      this.clearSession()
      window.location.reload()
    }
    return response.json().then((json: { data: T | null; error: null | { code: string } }) => {
      if (response.status !== 200) {
        throw new Error(json.error!.code || 'invalid_server_response')
      }
      return json.data!
    })
  }

  private getHeaders = (useToken: boolean) => {
    const token = storage.getToken()
    return {
      ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
      Platform: 'console',
      'User-Language': navigator?.language,
      'Content-Type': 'application/json',
    }
  }

  private get = async <T>(
    path: string,
    params: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => this.request<T>('GET', path, undefined, params, useToken)

  private delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('DELETE', path, undefined, params, useToken)

  private post = async <T>(
    path: string,
    body: {},
    useToken: boolean = true,
    params?: { [key: string]: string | string[] }
  ) => this.request<T>('POST', path, body, params, useToken)

  private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PUT', path, body, undefined, useToken)

  private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PATCH', path, body, undefined, useToken)

  private cleanParams = (params: {
    [key: string]: string | number | null | undefined | string[]
  }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== '')
      .mapValues((param) => {
        if (Array.isArray(param)) {
          return param.map((p) => String(p))
        }
        return String(param)
      })
      .value()

  private persistSession = (res: LoginResponse) => {
    storage.saveToken(res.token)
    storage.saveUser(res.user)
    return res
  }

  public clearSession = () => {
    storage.deleteToken()
    storage.deleteUser()
  }

  public login = ({ email, password }: { email: string; password: string }) =>
    this.post<LoginResponse>('/auth/login', { email, password }, false)
      .then((data) => {
        if (['admin', 'super_admin'].includes(data.user.role)) {
          return data
        }
        throw new Error('insufficient_role')
      })
      .then(this.persistSession)

  public recoverPassword = ({ email }: { email: string }) =>
    this.post<{}>('/auth/otp/request', { email }, false)

  public loginOTP = (body: { email: string; otp: string }) =>
    this.post<LoginResponse>('/auth/otp/login', body, false).then(this.persistSession)

  public updatePassword = ({
    password,
    session,
  }: {
    password: string
    session?: LoginResponse
  }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session)
    }
    return this.put<User>('/me/password', { password }).catch((err) => {
      if (session) {
        this.clearSession()
      }
      throw err
    })
  }

  public getMe = () => this.get<User>('/me', {})

  public updateMe = (body: UpdateUser) => this.patch<User>('/me', body)

  public getUsers = (params: ListParams = { page_size: 1_000_000 }) =>
    this.get<PaginatedResponse<User>>(
      '/users',
      this.cleanParams({
        ...params,
        page_size: 1_000_000,
      })
    )

  public getUser = (params: { id: string }) => this.get<User>(`/users/${params.id}`, {})

  public createUser = (body: CreateUser) => this.post<User>('/users', body)

  public updateUser = ({ id, body }: { id: string; body: UpdateUser }) =>
    this.patch<User>(`/users/${id}`, body)

  public deleteUser = (params: { id: string }) => this.delete<User>(`/users/${params.id}`, {})

  public exportUsers = (columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/users/export`, columns)

  public getMachines = (params: ListParams = { page_size: 1_000_000 }) =>
    this.get<PaginatedResponse<Machine>>(
      '/machine',
      this.cleanParams({
        ...params,
        page_size: 1_000_000,
      })
    )

  public getMachine = (params: { id: string }) => this.get<Machine>(`/machine/${params.id}`, {})

  public createMachine = (body: CreateMachine) => this.post<Machine>('/machine', body)

  public updateMachine = ({ id, body }: { id: string; body: UpdateMachine }) =>
    this.patch<Machine>(`/machine/${id}`, body)

  public deleteMachine = (params: { id: string }) =>
    this.delete<Machine>(`/machine/${params.id}`, {})

  public exportMachines = (columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/machine/export`, columns)

  public getCompanies = (params: ListParams = { page_size: 1_000_000 }) =>
    this.get<PaginatedResponse<Company>>(
      '/companies',
      this.cleanParams({
        ...params,
        page_size: 1_000_000,
      })
    )

  public getCompaniessBySearch = (search: string) =>
    this.get<PaginatedResponse<Pick<Company, 'id' | 'name'>>>(
      '/companies',
      this.cleanParams({ search, page: 0, page_size: 10, format: 'minimal' })
    )

  public getCompany = (params: { id: string }) => this.get<Company>(`/companies/${params.id}`, {})

  public createCompany = (body: CreateCompany) => this.post<Company>('/companies', body)

  public updateCompany = ({ id, body }: { id: string; body: UpdateCompany }) =>
    this.patch<Company>(`/companies/${id}`, body)

  public deleteCompany = (params: { id: string }) =>
    this.delete<Company>(`/companies/${params.id}`, {})

  public exportCompanies = (columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/companies/export`, columns)

  public getOrders = (params: ListParams = { order_by: 'code', page_size: 1_000_000 }) =>
    this.get<PaginatedResponse<Order>>(
      '/orders',
      this.cleanParams({
        ...params,
        page_size: 1_000_000,
      })
    )

  public getPaginatedOrders = (params: ListParams = { order_by: 'code', page_size: 1_000_000 }) =>
    this.get<PaginatedResponse<Order>>('/orders', this.cleanParams(params))

  public getOrder = (params: { id: string }) => this.get<Order>(`/orders/${params.id}`, {})

  public createOrder = (body: CreateOrder) => this.post<Order>('/orders', body)

  public updateOrder = ({ id, body }: { id: string; body: UpdateOrder }) =>
    this.patch<Order>(`/orders/${id}`, body)

  public deleteOrder = (params: { id: string }) => this.delete<Order>(`/orders/${params.id}`, {})

  public exportOrders = (columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/orders/export`, columns)

  public getOrderAggregateLogs = (params: ListParams & { order: string }) =>
    this.get<AggregatedLog[]>('/logs/aggregate', this.cleanParams(params))

  public getOrderAggregateMachineLogs = (params: ListParams & { order: string }) =>
    this.get<AggregatedMachineLog[]>('/machinelogs/aggregate', this.cleanParams(params))

  public getOrdersBySearch = (search: string, status?: OrderStatus) =>
    this.get<PaginatedResponse<Pick<Order, 'id' | 'code' | 'customer'>>>(
      '/orders',
      this.cleanParams({ search, page: 0, page_size: 10, status, format: 'minimal' })
    )

  public getUsersBySearch = (search: string, ignore_ids: string[] = [], role?: Role) =>
    this.get<PaginatedResponse<Pick<User, 'id' | 'email' | 'first_name' | 'last_name'>>>(
      '/users',
      this.cleanParams({
        search_text: search,
        page: 0,
        page_size: 10,
        format: 'minimal',
        ignore_ids,
        roles: role ? [role] : undefined,
      })
    )

  public getMachinesBySearch = (search: string, ignore_ids: string[] = []) =>
    this.get<PaginatedResponse<Pick<Machine, 'id' | 'code'>>>(
      '/machine',
      this.cleanParams({
        search_text: search,
        page: 0,
        page_size: 10,
        format: 'minimal',
        ignore_ids,
      })
    )

  public getLogs = (params: ListParams & LogFilters = { page_size: 1_000_000 }) =>
    this.get<PaginatedResponse<Log>>('/logs', this.cleanParams(params))

  public getLog = (params: { id: string }) => this.get<Log>(`/logs/${params.id}`, {})

  public createLog = (body: CreateLog) => this.post<Log>('/logs', body)

  public updateLog = ({ id, body }: { id: string; body: UpdateLog }) =>
    this.patch<Log>(`/logs/${id}`, body)

  public deleteLog = (params: { id: string }) => this.delete<Log>(`/logs/${params.id}`, {})

  public getLogsCSV = (params: { date_from: string; date_to: string }) =>
    this.getText(`/logs/export`, params, true)

  public getOrderCSV = (params: { order: string }) => this.getText(`/orders/export`, params, true)

  public exportLogs = (params: ListParams & LogFilters, columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/logs/export`, columns, undefined, this.cleanParams(params))

  public getMachineLogs = (params: ListParams & MachineLogFilters = { page_size: 20 }) =>
    this.get<PaginatedResponse<MachineLog>>('/machinelogs', this.cleanParams(params))

  public getMachineLog = (params: { id: string }) =>
    this.get<MachineLog>(`/machinelogs/${params.id}`, {})

  public createMachineLog = (body: CreateMachineLog) => this.post<MachineLog>('/machinelogs', body)

  public updateMachineLog = ({ id, body }: { id: string; body: UpdateMachineLog }) =>
    this.patch<MachineLog>(`/machinelogs/${id}`, body)

  public deleteMachineLog = (params: { id: string }) =>
    this.delete<MachineLog>(`/machinelogs/${params.id}`, {})

  public exportMachineLogs = (
    params: ListParams & LogFilters,
    columns: { [key: string]: string }
  ) =>
    this.post<{ url: string }>(`/machinelogs/export`, columns, undefined, this.cleanParams(params))

  public getEvents = (
    params: ListParams & { date?: string; from?: string; to?: string; statuses?: EventStatus[] }
  ) => this.get<PaginatedResponse<Event>>('/events', this.cleanParams(params))

  public getEvent = (params: { id: string }) => this.get<Event>(`/events/${params.id}`, {})

  public createEvent = (body: CreateEvent) => this.post<Event>('/events', body)

  public updateEvent = ({ id, body }: { id: string; body: UpdateEvent }) =>
    this.patch<Event>(`/events/${id}`, body)

  public updateEventStatus = ({ id, status }: { id: string; status: EventStatus }) =>
    this.patch<Event>(`/events/${id}/status`, { status })

  public deleteEvent = (params: { id: string }) => this.delete<Event>(`/events/${params.id}`, {})

  public exportEvents = (columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/events/export`, columns)

  public getEventTypes = (params: ListParams) =>
    this.get<PaginatedResponse<EventType>>('/events/types', this.cleanParams(params))

  public getEventType = (params: { id: string }) =>
    this.get<EventType>(`/events/types/${params.id}`, {})

  public createEventType = (body: CreateEventType) => this.post<EventType>('/events/types', body)

  public updateEventType = ({ id, body }: { id: string; body: UpdateEventType }) =>
    this.patch<EventType>(`/events/types/${id}`, body)

  public deleteEventType = (params: { id: string }) =>
    this.delete<EventType>(`/events/types/${params.id}`, {})

  public getEventTypesByTitle = (search_text: string) =>
    this.get<PaginatedResponse<EventType>>(
      '/events/types',
      this.cleanParams({ search_text, page: 0, page_size: 10, format: 'minimal' })
    )

  public exportEventTypes = (columns: { [key: string]: string }) =>
    this.post<{ url: string }>(`/events/types/export`, columns)

  public getDashboard = (params: ListParams & { date_from: string; date_to: string }) =>
    this.get<Dashboard>(`/dashboard`, this.cleanParams({ ...params }))
}

export type LoginResponse = { token: string; user: User }

export type ListParams = {
  search_text?: string
  order_by?: string
  order_direction?: 'asc' | 'desc'
  page?: number
  page_size?: number
  [key: string]: string | number | undefined | string[]
}

export type CreateUser = Pick<
  User,
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'role'
  | 'tax_code'
  | 'phone_number'
  | 'disable_notifications'
> & {
  password: string
  company_id: string
}
export type UpdateUser = Partial<
  Pick<
    User,
    'first_name' | 'last_name' | 'tax_code' | 'phone_number' | 'disable_notifications' | 'blocked'
  >
>

export type CreateMachine = Pick<Machine, 'code'> & { company_id: string }
export type UpdateMachine = CreateMachine

export type CreateCompany = Pick<Company, 'name' | 'max_employees'>
export type UpdateCompany = CreateCompany

export type CreateOrder = Pick<
  Order,
  'code' | 'customer' | 'description' | 'budget_minutes' | 'ddt' | 'delivery_date'
> & { company_id: string }
export type UpdateOrder = CreateOrder & Pick<Order, 'status'>

export type UpdateLog = Pick<Log, 'date' | 'logged_minutes'> & { order_id: string }
export type CreateLog = UpdateLog & { user_id: string; notes: string | null }
export type LogFilters = {
  user?: string
  order?: string
  order_status?: OrderStatus
  date_from?: string
  date_to?: string
}

export type UpdateMachineLog = Pick<Log, 'date' | 'logged_minutes'> & {
  order_id: string
  machine_id: string
}
export type CreateMachineLog = UpdateMachineLog & { user_id: string; notes: string | null }
export type MachineLogFilters = {
  user?: string
  order?: string
  machine?: string
  order_status?: OrderStatus
  date_from?: string
  date_to?: string
}

export type CreateEvent = Pick<Event, 'title' | 'period' | 'permissions'> & { type: string }
export type UpdateEvent = Partial<CreateEvent>

export type CreateEventType = Pick<EventType, 'name' | 'color'>
export type UpdateEventType = CreateEventType

export const api = new API()
