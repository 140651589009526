import React, { useContext } from 'react'
import styled from 'styled-components'
import { Label, Description, ThemeContext } from '@duckma/react-ds'
import AsyncSelect from 'react-select/async'

import { InputProps } from '@duckma/react-ds/dist/components/Input'

export type SelectEntry<T = string> = { value: T; label: string }
type Props<T = string> = Omit<InputProps, 'value' | 'onChange'> & {
  value?: SelectEntry<T> | null
  onChange?: (value: SelectEntry<T>) => void
  getItemsPromise?: (search: string) => Promise<SelectEntry<T>[]>
  emptyMessage?: string
  loadingMessage?: string
}

export function Select<T = string>({
  value,
  onChange,
  label = 'Selettore',
  disabled,
  placeholder = 'Seleziona un elemento',
  emptyMessage = 'Nessun elemento trovato',
  loadingMessage = 'Caricamento...',
  getItemsPromise,
  valid = true,
  errorText,
}: Props<T>) {
  const theme = useContext(ThemeContext)

  return (
    <div style={{ width: '100%' }}>
      {label !== '' && <Label text={label} style={{ marginBottom: '5px' }} color="black" />}
      <StyledSelect
        isDisabled={disabled}
        placeholder={placeholder}
        noOptionsMessage={() => emptyMessage}
        loadingMessage={() => loadingMessage}
        defaultOptions
        loadOptions={getItemsPromise}
        value={value}
        onChange={onChange}
        valid={valid}
        borderColor={theme['gray100']}
        errorBorderColor={theme['danger100']}
        styles={{
          control: (base: any) => ({
            ...base,
            height: '36px',
            minHeight: '36px',
            alignItems: 'flex-start',
            paddingTop: '1px',
          }),
          dropdownIndicator: (base: any) => ({
            ...base,
            paddingTop: '6px',
          }),
        }}
      />
      {!valid && (
        <Description
          text={errorText}
          color="danger100"
          style={{ marginTop: '5px', fontWeight: 400 }}
        />
      )}
    </div>
  )
}

const StyledSelect = styled(AsyncSelect)<{
  valid: boolean
  borderColor: string
  errorBorderColor: string
}>`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  > div {
    border: 1px solid ${(props) => (props.valid ? props.borderColor : props.errorBorderColor)};
    :hover {
      border: 1px solid ${(props) => (props.valid ? props.borderColor : props.errorBorderColor)};
    }
    border-radius: 2px;
  }
`
