import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Button, DateTimePicker, Input, NumericInput } from '@duckma/react-ds'
import moment from 'moment'
import _ from 'lodash'
import it from 'date-fns/locale/it'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { SelectEntry, Select } from '../../components/Select'

export const LogDetail = () => {
  const { id } = useParams<{ id: string }>()
  const createMode = !id

  const [getLog, { status, data }] = useRequest(api.getLog)
  const [createLog, { status: createStatus }] = useRequest(api.createLog)
  const [updateLog, { status: updateStatus }] = useRequest(api.updateLog)

  const [user, setUser] = useState<SelectEntry | null>(null)
  const [order, setOrder] = useState<SelectEntry | null>(null)
  const [date, setDate] = useState<Date | null>(null)
  const [hours, setHours] = useState<number>(0)
  const [minutes, setMinutes] = useState<number>(0)
  const [notes, setNotes] = useState<string | null>(null)

  const totalMinutes = useMemo(() => hours * 60 + minutes, [hours, minutes])

  useEffect(() => {
    if (hours < 0 || hours > 23) {
      setHours(_.clamp(hours, 0, 23))
    }
  }, [hours])

  useEffect(() => {
    if (minutes < 0) {
      if (hours > 0) {
        setMinutes(59)
        setHours(hours - 1)
      } else {
        setMinutes(0)
      }
    } else if (minutes > 59 && hours < 23) {
      setMinutes(0)
      setHours(hours + 1)
    }
  }, [minutes, hours])

  useEffect(() => {
    if (!createMode && id && status === 'idle') {
      getLog({ id })
    }
  }, [createMode, status, getLog, id])

  useSuccessToast(createStatus, 'Log creato con successo', '/logs')
  useSuccessToast(updateStatus, 'Log modificato con successo')

  useEffect(() => {
    if (data) {
      const { user, order, date, logged_minutes, notes } = data
      setUser({ value: user.id, label: `${user.first_name} ${user.last_name}` })
      setOrder({ value: order.id, label: `${order.customer} (${order.code})` })
      setDate(moment(date).toDate())
      setHours(Math.floor(logged_minutes / 60))
      setMinutes(Math.floor(logged_minutes % 60))
      setNotes(notes || '')
    }
  }, [data])

  return (
    <Container>
      <FormContainer
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          if (!user || !order || !date) {
            return
          }
          if (createMode) {
            createLog({
              user_id: user.value,
              order_id: order.value,
              logged_minutes: totalMinutes,
              date: moment(date).format('YYYY-MM-DD'),
              notes,
            })
          } else {
            updateLog({
              id: id!,
              body: {
                order_id: order.value,
                logged_minutes: totalMinutes,
                date: moment(date).format('YYYY-MM-DD'),
              },
            })
          }
        }}
      >
        <FieldsContainer>
          <Select
            name="utente"
            label="Utente"
            emptyMessage="Nessun utente trovato"
            placeholder="Seleziona Utente"
            value={user}
            onChange={setUser}
            disabled={!createMode}
            getItemsPromise={(search) =>
              api.getUsersBySearch(search, [], 'user').then((result) =>
                result.items.map(({ id, first_name, last_name }) => ({
                  value: id,
                  label: `${first_name} ${last_name}`,
                }))
              )
            }
          />
          <Select
            name="commessa"
            label="Commessa"
            emptyMessage="Nessuna commessa trovata"
            placeholder="Seleziona commessa"
            value={order}
            onChange={setOrder}
            getItemsPromise={(search) =>
              api.getOrdersBySearch(search).then((result) =>
                result.items.map(({ id, code, customer }) => ({
                  value: id,
                  label: `${customer} (${code})`,
                }))
              )
            }
          />
          <DateRow>
            <DateTimePicker
              name="seleziona-il-giorno"
              locale={it}
              selected={date}
              onChange={setDate}
              placeholderText="Seleziona il giorno"
            />
            <NumericInput name="ore" value={hours} onChange={setHours} />
            <NumericInput name="minuti" value={minutes} onChange={setMinutes} />
          </DateRow>
          <Input
            name="note"
            rows={5}
            value={notes || ''}
            placeholder="Nessuna nota inserita"
            disabled={!createMode}
            onChange={setNotes}
          />
        </FieldsContainer>
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={
              !user || !order || !date || isNaN(minutes) || isNaN(hours) || totalMinutes <= 0
            }
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 20px;
  }
`

const DateRow = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    > :nth-child(1) {
      grid-column: 1 / 3;
    }
  }
  grid-column-gap: 20px;
  > :nth-child(1) {
    height: 80px;
  }
`
