import React from 'react'
import parseValue from 'parse-duration'
import { TextBox } from 'devextreme-react'

export const EditDuration: React.FC<any> = (props) => {
  return (
    <TextBox
      placeholder="Enter password"
      showClearButton={true}
      defaultValue={props.data.text}
      onValueChanged={(e) =>
        props.data.setValue(
          parseValue(
            (e.value ?? '')
              .replace(/ore|ora|o/, 'hours')
              .replace('minuti', 'minutes')
              .replace('minuto', 'minutes'),
            'm'
          )
        )
      }
    />
  )
}
