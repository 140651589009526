import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useRequest } from '../../../hooks/useRequest'
import { api } from '../../../data/api'
import { Ordering, Column, Tag, Button, Table, Paginator, Subtitle } from '@duckma/react-ds'
import { Event } from '../../../data/models'
import { toast } from 'react-toastify'
import { SkeletonTable } from '../../../components/SkeletonTable'
import moment from 'moment'

export const RequestsTab: React.FC<{ onChangeCount: () => void }> = ({ onChangeCount }) => {
  const [getRequests, { status, data }] = useRequest(api.getEvents)
  const [updateRequestStatus, { data: updateData }, resetUpdate] = useRequest(api.updateEventStatus)
  const [deleteRequest, { data: deleteData }, resetDelete] = useRequest(api.deleteEvent)

  const [ordering, setOrdering] = useState<Ordering<Event>>({
    key: 'created_at',
    direction: 'desc',
  })
  const [page, setPage] = useState<number>(0)

  const getRequestsWithParams = useCallback(
    () =>
      getRequests({
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 5,
        statuses: ['requested', 'delete_requested'],
      }),
    [getRequests, ordering, page]
  )

  useEffect(() => {
    getRequestsWithParams()
  }, [getRequestsWithParams])

  useEffect(() => {
    if (data) {
      onChangeCount()
    }
  }, [data, onChangeCount])

  useEffect(() => {
    if (updateData != null) {
      const oldStatus = data?.items.find((e) => e.id === updateData.id)?.status
      if (oldStatus === 'requested') {
        if (updateData.status === 'accepted') {
          toast('Evento accettato.', { type: 'success' })
        } else if (updateData.status === 'declined') {
          toast('Evento declinato.', { type: 'success' })
        }
      }
      if (oldStatus === 'delete_requested') {
        toast('Richiesta di cancellazione rifiutata.', { type: 'success' })
      }
      getRequestsWithParams()
      resetUpdate()
    }
  }, [data, updateData, resetUpdate, getRequestsWithParams])

  useEffect(() => {
    if (deleteData != null) {
      const oldStatus = data?.items.find((e) => e.id === deleteData.id)?.status
      if (oldStatus === 'delete_requested') {
        toast('Richiesta di cancellazione accettata.', { type: 'success' })
      }
      getRequestsWithParams()
      resetDelete()
    }
  }, [data, deleteData, resetDelete, getRequestsWithParams])

  const columns = useMemo(
    () =>
      [
        {
          key: 'title',
          name: 'Nome',
          orderable: true,
          render: (event) => {
            switch (event.status) {
              case 'delete_requested':
                return `${event.title} (richiesta cancellazione)`
              default:
                return event.title
            }
          },
        },
        {
          key: 'owner',
          name: 'Utente',
          orderable: false,
          render: (event) => (
            <a href={`/users/${event.owner?.id}`}>{event.owner?.email || 'temp'}</a>
          ),
        },
        {
          key: 'type',
          name: 'Tipo',
          orderable: true,
          render: (event) => (
            <Tag text={event.type.name} style={{ backgroundColor: event.type.color }} />
          ),
        },
        {
          key: 'from',
          name: 'Data ed ora',
          orderable: true,
          render: (event) => (
            <span>
              Dal <b>{moment(event.period.from).format('DD/MM/YYYY HH:mm')}</b> al{' '}
              <b>{moment(event.period.to).format('DD/MM/YYYY HH:mm')}</b>
            </span>
          ),
        },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: (event) => (
            <Actions>
              <Button
                size="icon"
                iconLeft="ok"
                color="success"
                radius={24}
                onClick={() => {
                  if (
                    window.confirm(
                      "Sei sicuro di voler accettare la richiesta? L'azione è irreversibile."
                    )
                  ) {
                    switch (event.status) {
                      case 'requested':
                        updateRequestStatus({ id: event.id, status: 'accepted' })
                        break
                      default:
                        deleteRequest({ id: event.id })
                        break
                    }
                  }
                }}
              />
              <Button
                size="icon"
                iconLeft="exit"
                color="danger"
                radius={24}
                onClick={() => {
                  if (
                    window.confirm(
                      "Sei sicuro di voler rifiutare la richiesta? L'azione è irreversibile."
                    )
                  ) {
                    switch (event.status) {
                      case 'requested':
                        updateRequestStatus({ id: event.id, status: 'declined' })
                        break
                      default:
                        updateRequestStatus({ id: event.id, status: 'accepted' })
                        break
                    }
                  }
                }}
              />
            </Actions>
          ),
        },
      ] as Column<Event>[],
    [updateRequestStatus, deleteRequest]
  )

  return (
    <TabContainer>
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessuna richiesta al momento attiva." color="gray100" />
      )}{' '}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  min-width: 70vw;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`
