import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { Button, Title, Subtitle } from '@duckma/react-ds'
import { useRequest } from '../../hooks/useRequest'
import { api, LoginResponse } from '../../data/api'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import { PasswordField } from '../../components/PasswordField'

export const UpdatePassword = () => {
  const { state } = useLocation<{ session?: LoginResponse }>()
  const history = useHistory()

  const [password, setPassword] = useState('')
  const [valid, setValid] = useState(false)
  const onChange = useCallback((newPassword, isValid) => {
    setPassword(newPassword)
    setValid(isValid)
  }, [])

  const [request, { status, data }] = useRequest(api.updatePassword)

  useEffect(() => {
    if (!state || !state.session) {
      history.push('/users')
    }
  }, [state, history])

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ password, session: state.session! })
      }}
    >
      {data && <Redirect to="/users" />}
      <Title text="Recupera Password" style={{ marginBottom: '33px' }} />
      <Subtitle text="Inserisci la tua nuova password" style={{ marginBottom: '84px' }} />
      <PasswordField value={password} onChange={onChange} large />
      <Button
        text="Procedi"
        radius={4}
        disabled={!valid}
        loading={status === 'loading'}
        style={{ width: '50%', marginTop: '50px' }}
      />
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 20vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`
