import React, { useEffect, useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { Route, useHistory, Switch } from 'react-router-dom'
import { Table, Paginator, Subtitle, Column, Ordering } from '@duckma/react-ds'
import { toast } from 'react-toastify'

import { EventTypeDetail } from './components/EventTypeDetail'
import { SkeletonTable } from '../../components/SkeletonTable'
import { EventType } from '../../data/models'
import { TableActions } from '../../components/TableActions'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'

export const EventTypesPage = () => {
  const history = useHistory()
  const [getTypes, { status, data }] = useRequest(api.getEventTypes)
  const [deleteType, { data: deleteData }, resetDelete] = useRequest(api.deleteEventType)

  const [ordering, setOrdering] = useState<Ordering<EventType>>({
    key: 'name',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)

  const getTypesWithParams = useCallback(
    () =>
      getTypes({
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 7,
      }),
    [getTypes, ordering, page]
  )

  useEffect(() => {
    getTypesWithParams()
  }, [getTypesWithParams])

  useEffect(() => {
    if (deleteData != null) {
      toast('Tipo di evento eliminato con successo', { type: 'success' })
      getTypesWithParams()
      resetDelete()
      history.push('/event-types')
    }
  }, [deleteData, resetDelete, getTypesWithParams, history])

  const onDetailChange = useCallback(() => {
    setOrdering({ key: 'updated_at', direction: 'desc' })
  }, [])

  const columns = useMemo(
    () =>
      [
        { key: 'name', name: 'Nome', orderable: true },
        {
          key: 'color',
          name: 'Etichetta',
          orderable: false,
          render: ({ color }) => (
            <div
              style={{ backgroundColor: color, width: '16px', height: '16px', borderRadius: '2px' }}
            />
          ),
        },
        {
          key: 'actions',
          name: 'Azioni',
          render: (type) => (
            <TableActions
              onEdit={() => history.push(`/event-types/${type.id}`)}
              onDelete={() => deleteType({ id: type.id })}
              confirmDeleteMessage={`Sei sicuro di voler cancellare il tipo di evento (${type.name})? L'azione è irreversibile.`}
            />
          ),
        },
      ] as Column<EventType>[],
    [deleteType, history]
  )

  return (
    <Container>
      <Left>
        <TableContainer>
          <>
            {status !== 'loading' ? (
              <Table
                columns={columns}
                records={data ? data.items : []}
                order={ordering}
                onOrder={setOrdering}
              />
            ) : (
              <SkeletonTable />
            )}
            {status === 'success' && data && data.items.length > 0 && (
              <Paginator
                style={{ marginTop: '75px', marginBottom: '75px' }}
                currentPage={page}
                totalPages={data.pagination.total_pages}
                onPageSelect={setPage}
              />
            )}
            {status === 'success' && data && data.items.length === 0 && (
              <Subtitle text="Nessun tipo di evento trovato" />
            )}
          </>
        </TableContainer>
      </Left>
      <Right>
        <Switch>
          <Route path="/event-types/:id">
            <EventTypeDetail onUpdate={onDetailChange} />
          </Route>
          <Route path="/event-types">
            <EventTypeDetail onUpdate={onDetailChange} />
          </Route>
        </Switch>
      </Right>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  padding-left: 50px;
  padding-right: 50px;
  min-height: 100%;
`

const Left = styled.div`
  grid-area: 1 / 1 / 2 / 2;
`

const Right = styled.div`
  grid-area: 1 / 2 / 2 / 3;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`
