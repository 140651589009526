import React from 'react'
import { ThemeContext } from '@duckma/react-ds'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { theme } from './styles/theme'
import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { storage } from './data/storage'
import { UsersPage } from './modules/users/Users'
import { MachinesPage } from './modules/machines/Machines'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayout } from './components/MainLayout'
import { ProfilePage } from './modules/profile/Profile'
import { OrdersPage } from './modules/orders/Orders'
import { CreateUser } from './modules/users/CreateUser'
import { CreateMachine } from './modules/machines/CreateMachine'
import { MachineDetail } from './modules/machines/MachineDetail'
import { OrderDetail } from './modules/orders/OrderDetail'
import { EventsPage } from './modules/events/Events'
import { EventTypesPage } from './modules/events/EventTypes'
import { DashboardPage } from './modules/dashboard/Dashboard'
import { LogsPage } from './modules/logs/Logs'
import { MachineLogsPage } from './modules/machinelogs/MachineLogs'
import { LogDetail } from './modules/logs/LogDetail'
import { MachineLogDetail } from './modules/machinelogs/MachineLogDetail'
import { CreateCompany } from './modules/companies/CreateCompany'
import { CompanyDetail } from './modules/companies/CompanyDetail'
import { CompaniesPage } from './modules/companies/Companies'

const PrivateRoute = ({
  component: Component,
  header,
  ...rest
}: {
  component: React.ReactType
  header: React.ComponentProps<typeof MainLayout>
  [key: string]: any
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getToken() !== null ? (
          <MainLayout {...header}>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

function App() {
  return (
    <ThemeContext.Provider value={theme}>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/password-reset">
            <PasswordReset />
          </Route>
          <Route path="/password-otp">
            <PasswordOTP />
          </Route>
          <Route path="/update-password">
            <UpdatePassword />
          </Route>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <PrivateRoute
            path="/dashboard"
            component={DashboardPage}
            header={{
              hideHeader: true,
            }}
          />
          <PrivateRoute
            path="/users/create/admin"
            component={CreateUser}
            header={{
              title: 'Nuovo Amministratore',
              backLink: {
                text: 'Torna alla lista utenti',
                to: '/users',
              },
            }}
          />
          <PrivateRoute
            path="/users/create"
            component={CreateUser}
            header={{
              title: 'Nuovo Utente',
              backLink: {
                text: 'Torna alla lista utenti',
                to: '/users',
              },
            }}
          />
          <PrivateRoute
            path="/machine/create"
            component={CreateMachine}
            header={{
              title: 'Nuova Macchina',
              backLink: {
                text: 'Torna alla lista macchine',
                to: '/machines',
              },
            }}
          />
          <PrivateRoute
            path="/machines/:id"
            component={MachineDetail}
            header={{
              title: 'Dettaglio Macchina',
              backLink: {
                text: 'Torna alla lista macchine',
                to: '/machines',
              },
            }}
          />
          <PrivateRoute
            path="/companies/create"
            component={CreateCompany}
            header={{
              title: 'Nuova Azienda',
              backLink: {
                text: 'Torna alla lista aziende',
                to: '/companies',
              },
            }}
          />
          <PrivateRoute
            path="/companies/:id"
            component={CompanyDetail}
            header={{
              title: 'Dettaglio Azienda',
              backLink: {
                text: 'Torna alla lista aziende',
                to: '/companies',
              },
            }}
          />
          <PrivateRoute
            path="/users"
            component={UsersPage}
            header={{
              title: 'Utenti',
              headerButton: {
                text: 'Aggiungi utente',
                to: '/users/create',
              },
              headerOutlineButton: {
                text: 'Aggiungi amministratore',
                to: '/users/create/admin',
              },
            }}
          />
          <PrivateRoute
            path="/machines"
            component={MachinesPage}
            header={{
              title: 'Macchine',
              headerButton: {
                text: 'Aggiungi nuova Macchina',
                to: '/machine/create',
              },
            }}
          />
          <PrivateRoute
            path="/companies"
            component={CompaniesPage}
            header={{
              title: 'Aziende',
              headerButton: {
                text: 'Aggiungi nuova Azienda',
                to: '/companies/create',
              },
            }}
          />
          <PrivateRoute
            path="/orders/create"
            component={OrderDetail}
            header={{
              title: 'Nuova Commessa',
              backLink: {
                text: 'Torna alla lista commesse',
                to: '/orders',
              },
            }}
          />
          <PrivateRoute
            path="/orders/:id"
            component={OrderDetail}
            header={{
              title: 'Dettaglio Commessa',
              backLink: {
                text: 'Torna alla lista commesse',
                to: '/orders',
              },
            }}
          />
          <PrivateRoute
            path="/orders"
            component={OrdersPage}
            header={{
              title: 'Commesse',
              headerButton: {
                text: 'Nuova Commessa',
                to: '/orders/create',
              },
            }}
          />
          <PrivateRoute
            path="/logs/create"
            component={LogDetail}
            header={{
              title: 'Aggiungi log ore',
              backLink: {
                text: 'Torna alla lista log ore',
                to: '/logs',
              },
            }}
          />
          <PrivateRoute
            path="/machinelogs/create"
            component={MachineLogDetail}
            header={{
              title: 'Nuovo log ore macchina',
              backLink: {
                text: 'Torna alla lista log ore macchina',
                to: '/machinelogs',
              },
            }}
          />
          <PrivateRoute
            path="/logs/:id"
            component={LogDetail}
            header={{
              title: 'Dettaglio log ore',
              backLink: {
                text: 'Torna alla lista log ore',
                to: '/logs',
              },
            }}
          />
          <PrivateRoute
            path="/machinelogs/:id"
            component={MachineLogDetail}
            header={{
              title: 'Dettaglio log ore macchina',
              backLink: {
                text: 'Torna alla lista log ore macchina',
                to: '/machinelogs',
              },
            }}
          />
          <PrivateRoute
            path="/logs"
            component={LogsPage}
            header={{
              title: 'Log ore',
              headerButton: {
                text: 'Nuovo log ore',
                to: '/logs/create',
              },
            }}
          />
          <PrivateRoute
            path="/machinelogs"
            component={MachineLogsPage}
            header={{
              title: 'Log ore macchina',
              headerButton: {
                text: 'Nuovo log ore macchina',
                to: '/machinelogs/create',
              },
            }}
          />
          <PrivateRoute
            path="/events"
            component={EventsPage}
            header={{
              title: 'Calendario Globale',
              headerButton: {
                text: 'Nuovo Evento',
                to: '/events/create',
              },
            }}
          />
          <PrivateRoute
            path="/event-types"
            component={EventTypesPage}
            header={{
              title: 'Tipologie Evento',
              headerButton: {
                text: 'Nuova Tipologia Evento',
                to: '/event-types/create',
              },
            }}
          />
          <PrivateRoute
            path="/me"
            component={ProfilePage}
            header={{
              title: 'Profilo',
            }}
          />
        </Switch>
      </Router>
    </ThemeContext.Provider>
  )
}

export default App
