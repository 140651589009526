import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Button, Input, SectionTitle } from '@duckma/react-ds'

import { useRequest } from '../../../hooks/useRequest'
import { api, CreateEventType } from '../../../data/api'
import { useSuccessToast } from '../../../hooks/useSuccessToast'
import { ColorPicker } from '../../../components/ColorPicker'
import Skeleton from 'react-loading-skeleton'

type Props = {
  onUpdate: () => void
}
export const EventTypeDetail = ({ onUpdate }: Props) => {
  const { id } = useParams()
  const createMode = !id || id === 'create'

  const [getType, { status, data }] = useRequest(api.getEventType)
  const [createType, { data: createData, status: createStatus }] = useRequest(api.createEventType)
  const [updateType, { status: updateStatus }] = useRequest(api.updateEventType)

  const [values, setValues] = useState<CreateEventType>({ name: '', color: '#DD7631' })
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    setShowError(false)
    if (!createMode && id) {
      getType({ id })
    } else {
      setValues({ name: '', color: '#DD7631' })
    }
  }, [createMode, getType, id])

  useEffect(() => {
    if (updateStatus === 'success') {
      onUpdate()
    }
  }, [updateStatus, onUpdate])

  useEffect(() => {
    if (createStatus === 'success') {
      onUpdate()
    }
  }, [createStatus, onUpdate])

  useSuccessToast(
    createStatus,
    'Tipo di evento creato con successo',
    `/event-types/${createData?.id}`
  )
  useSuccessToast(updateStatus, 'Tipo di evento modificato con successo')

  useEffect(() => {
    if (data) {
      setValues({ name: data.name, color: data.color })
    }
  }, [data])

  return (
    <Container>
      <SectionTitle text={`${createMode ? 'Nuovo' : 'Dettaglio'} Tipo Di Evento`} color="black" />
      <FormContainer
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          if (createMode) {
            createType({
              ...values,
            })
          } else {
            updateType({
              id: id!,
              body: {
                ...values,
              },
            })
          }
        }}
      >
        <FieldsContainer>
          {status === 'loading' ? (
            <SkeletonContainer>
              <Skeleton height={50} />
              <Skeleton height={30} />
            </SkeletonContainer>
          ) : (
            <>
              <Input
                name="titolo"
                value={values.name}
                onChange={(name) => setValues((v) => ({ ...v, name }))}
                onBlur={() => setShowError(true)}
                errorText="Campo obbligatorio"
                valid={!showError || values.name.length > 0}
                autocomplete="off"
              />
              <ColorPicker
                value={values.color!}
                onChange={(color) => setValues((v) => ({ ...v, color }))}
              />
            </>
          )}
        </FieldsContainer>
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={status === 'loading' || values.name.length === 0}
            loading={[createStatus, updateStatus].includes('loading')}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
  border-radius: 10px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  background-color: white;
  box-shadow: 0 2px 34px 0 rgba(130, 136, 148, 0.21);
  min-width: 350px;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 30px;
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
  margin-top: 40px;
`
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 30px;
  }
`

const SkeletonContainer = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
