import React, { useState } from 'react'
import styled from 'styled-components'

import { api } from '../../data/api'
import { CustomDataGrid } from '../../components/CustomDataGrid'
import { Column, Lookup } from 'devextreme-react/data-grid'
import { SelectBox } from 'devextreme-react'
import { dateFormatter, durationFormatter, ellipse } from '../../utils/formatters'
import { EditDuration } from '../../utils/durationEditor'
import CustomStore from 'devextreme/data/custom_store'
import { storage } from '../../data/storage'
import { OrdersTabPage } from './components/OrdersTab'
import { OrderStatus } from '../../data/models'
import { Tabs } from '@duckma/react-ds'

export const statuses = [
  {
    id: 'active',
    name: 'Commessa aperta',
  },
  {
    id: 'pending',
    name: 'Commessa sospesa',
  },
  {
    id: 'closed',
    name: 'Commessa chiusa',
  },
]

const companiesDataSource = {
  store: new CustomStore({
    key: 'id',
    load: async (loadOptions: unknown) =>
      api.getCompanies().then(({ items, pagination }) => ({
        data: items,
        totalCount: pagination.total_items,
        summary: pagination.total_pages,
        groupCount: pagination.page_size,
      })),
    byKey: (key: string) => api.getCompany({ id: key }),
  }),
}

export const OrdersPage = () => {
  const [author] = useState(storage.getUser())
  const [tab, setTab] = useState('open')
  return (
    <Container>
      <Tabs
        currentTab={tab}
        onTabChange={setTab}
        tabs={[
          {
            key: 'open',
            label: 'Aperte',
            child: <OrdersTabPage status="active" />,
          },
          {
            key: 'pending',
            label: 'Sospese',
            child: <OrdersTabPage status="pending" />,
          },
          {
            key: 'closed',
            label: 'Archivio',
            child: <OrdersTabPage status="closed" />,
          },
        ]}
      ></Tabs>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  * > textarea {
    box-sizing: border-box; /* fit parent width */
    height: 200px !important;
    line-height: 1.2 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
`
