import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Table, Column, Ordering, Subtitle } from '@duckma/react-ds'

import { api } from '../../../data/api'
import { useRequest } from '../../../hooks/useRequest'
import { SkeletonTable } from '../../../components/SkeletonTable'
import { durationFormatter } from '../../../utils/formatters'
import { AggregatedLog } from '../../../data/models'

type Row = AggregatedLog & { 'user.first_name'?: string; 'user.last_name'?: string }

export const AggregatedLogsSection: React.FC<{ id: string }> = ({ id }) => {
  const [getLogs, { status, data }] = useRequest(api.getOrderAggregateLogs)

  const [ordering, setOrdering] = useState<Ordering<Row>>({
    key: 'user.first_name',
    direction: 'asc',
  })

  const getLogsWithParams = useCallback(
    () =>
      getLogs({
        order_by: ordering.key,
        order_direction: ordering.direction,
        order: id,
      }),
    [id, getLogs, ordering]
  )

  useEffect(() => {
    getLogsWithParams()
  }, [getLogsWithParams])

  const columns = useMemo(
    () =>
      [
        {
          key: 'user.first_name',
          name: 'Nome',
          orderable: true,
          render: ({ user }) => `${user.first_name}`,
        },
        {
          key: 'user.last_name',
          name: 'Cognome',
          orderable: true,
          render: ({ user }) => `${user.last_name}`,
        },
        {
          key: 'logged_minutes',
          name: 'Totale ore registrate',
          orderable: true,
          render: ({ logged_minutes }) => durationFormatter(logged_minutes),
        },
      ] as Column<Row>[],
    []
  )

  return (
    <Container>
      {status !== 'loading' ? (
        <Table columns={columns} records={data || []} order={ordering} onOrder={setOrdering} />
      ) : (
        <SkeletonTable />
      )}
      {['success', 'error'].includes(status) && !data?.length && (
        <Subtitle text="Nessun log trovato" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 50px;
`
